import service from '@/utils/request';
//getModel3cList
export var getModel3cList = function getModel3cList(params) {
  return service({
    url: "/map/getModel3cList",
    method: 'get',
    params: params
  });
};

//getLineSumList
export var getLineSumList = function getLineSumList(params) {
  return service({
    url: "/map/getLineSumList",
    method: 'get',
    params: params
  });
};
//getBarSumList
export var getBarSumList = function getBarSumList(params) {
  return service({
    url: "/map/getBarSumList",
    method: 'get',
    params: params
  });
};
//getWorkshopSumList
export var getWorkshopSumList = function getWorkshopSumList(params) {
  return service({
    url: "/map/getWorkshopSumList",
    method: 'get',
    params: params
  });
};
//getChargeStateSumList
export var getChargeStateSumList = function getChargeStateSumList(params) {
  return service({
    url: "/map/getChargeStateSumList",
    method: 'get',
    params: params
  });
};
//getMapStateSumList
export var getMapStateSumList = function getMapStateSumList(params) {
  return service({
    url: "/map/getMapStateSumList",
    method: 'get',
    params: params
  });
};
//getAllRptWeek
export var getAllRptWeek = function getAllRptWeek(params) {
  return service({
    url: "/map/getAllRptWeek",
    method: 'get',
    params: params
  });
};
//getStartAndEndDate
export var getStartAndEndDate = function getStartAndEndDate(params) {
  return service({
    url: "/map/getStartAndEndDate",
    method: 'get',
    params: params
  });
};
//getAllWarningType
export var getAllWarningType = function getAllWarningType(params) {
  return service({
    url: "/map/getAllWarningType",
    method: 'get',
    params: params
  });
};